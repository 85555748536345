import React from 'react'
import "../css/Loading.css";
const env = require('../../config.env')
const Loading = () => {
    return (
        <div className="spinner-container w-100">
            <div className="loading-spinner">
                <img src={`${env.SERVER_MEDIA_PATH}loading.gif`} />
            </div>
        </div>
    )
}
export default Loading
