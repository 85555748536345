import React, { useContext, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import SignUpStep1 from '../Components/SignUpStep1'
import SignUpStep2 from '../Components/SignUpStep2'
import { useErrorBoundary } from "react-error-boundary";
import '../css/Authentication.css'
import { useNavigate } from 'react-router-dom';
const env = require('../../config.env')


const SignUp = () => {
    const navigate = useNavigate()
    const { showBoundary } = useErrorBoundary();
    const [stepCount, setstepCount] = useState(0)
    const [step1, setstep1] = useState('')
    const [step2, setstep2] = useState('')
    const [step3, setstep3] = useState('')
    const [step4, setstep4] = useState('')
    const [step1_content, setstep1_content] = useState('')
    const [step2_content, setstep2_content] = useState('')
    const [cretebtn, setcretebtn] = useState('')

    const [Name, setName] = useState('')
    const [UserName, setUserName] = useState('')
    const [Email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [Affiliation, setAffiliation] = useState('')
    const [Position, setPosition] = useState('')
    const [countryId, setCountryId] = useState(0)
    const [interests, getInterests] = useState([])
    const [hasError, setHasError] = useState(false)
    const [errMsg, setErrMsg] = useState()
    const [termsAccepted, settermsAccepted] = useState(false)

    useEffect(() => {
        setstepCount(2)
        setstep1("step-active")
        setstep2("step-non-active")
        setstep1_content("")
        setstep2_content("hidden")
        setcretebtn("hidden")
        //  setnextbtn("")
    }, [])
    const nextstep = () => {
        setstepCount((prev) => prev + 1);
        restErrors()
        if (stepCount > 1) {
            setstep1("step-non-active")
            setstep2("step-active")
            setstep3("step-non-active")
            setstep4("step-non-active")
            setstep1_content("hidden")
            setstep2_content("")
            setcretebtn("")
        }
    }

    const restErrors = () => {
        setHasError(false)
        setErrMsg('')
    }
    const showErrors = (err) => {
        setHasError(true)
        setErrMsg(err)
    }
    const handleErrors = (err) => {
        if (err.status == 500)
            showBoundary(err)
        else {
            showErrors(err.message)
        }
    }

    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }
    const onInputChange = (event, interests) => {
        let changedInput;
        let changedValue;
        if (event == "interests") {
            getInterests(interests)
        } else {
            changedInput = event.target.name
            changedValue = event.target.value

            switch (changedInput) {
                case 'Name':
                    setName(changedValue)
                    return
                case 'UserName':
                    setUserName(changedValue)
                    return
                case 'Email':
                    setEmail(changedValue)
                    return
                case 'Password':
                    setPassword(changedValue)
                    return
                case 'Affiliation':
                    setAffiliation(changedValue)
                    return
                case 'Position':
                    setPosition(changedValue)
                    return
                case 'country':
                    setCountryId(changedValue)
                    return
                case 'terms':
                    settermsAccepted(event.target.checked)
                    return
            }
        }

    }
    let optimisedHandleChange = useCallback(
        debounce(onInputChange, 500)
        ,
        []
    );


    const createAccount = () => {
        if (termsAccepted) {
            let user = {
                username: UserName, firstname: Name, password: Password,
                email: Email, affiliation: Affiliation, position: Position, countryId: countryId, interests: interests
            }
            axios.post(env.SERVER_REQUEST_PATH + 'signup', user)
                .then((response) => {
                    let status = response.status
                    if (status == 200) {
                        localStorage.setItem("user-token", response.data.user_token)

                        restErrors()
                        navigate('/login')
                    }

                }).catch(err => {
                    handleErrors(err)
                    console.error(`Error: ${err}`)
                })
        }
        else {
            showErrors("Terms and Conditions is required")
        }
    }

    return (
        <div className='row w-100 h-100 m-0 position-absolute sign-up-container'>
            <div className='col-12 col-md-6 px-4 py-4 auth-container'>
                <div className="tab-content justify-content-between d-flex flex-column h-100 w-100 gap-3" id="pills-tabContent">
                    <a href="/" className='d-flex align-items-center logo-t-style'>
                        <img src=
                            {env.SERVER_MEDIA_PATH + 'pageLogo.png'}
                            className='auth-logo' />
                    </a>
                    <div className="auth-form-container mx-auto" id="loginformContainer">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="auth-page-wrapper active" hidden={step1_content} id="signup1-content">
                                <SignUpStep1 hasError={hasError} errMsg={errMsg} onChange={optimisedHandleChange} nextstep={nextstep} />
                            </div>
                            <div className="auth-page-wrapper" hidden={step2_content} id="signup2-content">
                                <SignUpStep2 hasError={hasError} errMsg={errMsg} onChange={optimisedHandleChange} />
                            </div>

                            <div className='auth-btns' hidden={cretebtn}>
                                <Button type="submit" onClick={createAccount} disabled={termsAccepted ? '' : 'disabled'}
                                    className={"w-100 mb-3"} hidden={cretebtn}>Complete</Button>
                            </div>
                        </div>
                    </div>

                    <div className="stepwizard w-100" id="pills-tab">
                        <div className='stepwizard-row d-flex justify-content-center gap-3 setup-panel' >
                            <div onClick={() => {
                                // setstepCount()
                                setstep1("step-active")
                                setstep2("step-non-active")
                                setstep1_content("")
                                setstep2_content("hidden")
                                setcretebtn("hidden")
                            }}
                                className={`stepwizard-step ${step1}`} role="signup1"></div>
                            <div onClick={() => {
                                setstep1("step-non-active")
                                setstep2("step-active")
                                setstep1_content("hidden")
                                setstep2_content("")
                                setcretebtn("")
                            }} className={`stepwizard-step ${step2}`} role="signup2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 p-4 h-100 d-none d-md-flex">
                <div className='auth-img-container d-flex align-items-center justify-content-center h-100 w-100'>
                    <div className='content w-75'>
                        <h2>Built for researchers, by researchers.</h2>
                        <p>Be part of our academic community operated by artificial intelligence</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp
