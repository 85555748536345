import React, { useState, useEffect, useRef } from 'react';
import FeedsGrid from '../Components/Main/Feeds/FeedsGrid';
import { useErrorBoundary } from "react-error-boundary";
import { useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios'
import Footer from '../../SharedComponents/js/Footer';
import Header from '../../Main/Components/Header'
import { useStateValue } from "../../index";
import UserGrid from '../Components/Main/Search/UserGrid';
import { PropagateLoader } from 'react-spinners';
import FingerLeftMenu from '../Components/Main/FingerLeftMenu';
import MiniFooter from '../../SharedComponents/js/Mini-Footer';
import Navbar from '../../SharedComponents/js/Navbar';
const env = require('../../config.env')

const SearchResult = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageCount, setpageCount] = useState(1)
    const [pageMCount, setpageMCount] = useState(1)
    const totalCount = useRef()
    const totalMCount = useRef()
    const scrolledCount = useRef()
    const scrolledMCount = useRef()
    const [searchType, setsearchType] = useState(1)
    const [serachValue, setserachValue] = useState()
    const location = useLocation();
    const videosref = useRef();
    const videos_material_ref = useRef();

    const [users, setusers] = useState([]);
    const { showBoundary } = useErrorBoundary();
    const [videos, setVideos] = useState();
    const [usertotalCount, setUsertotalcount] = useState(0)
    const [userDetails, dispatch] = useStateValue();
    const [foundResults, setFoundResults] = useState(false)
    const [DataIsLoaded, setDataIsLoaded] = useState()
    const [searchtabs,] = useState([
        { text: "Videos", type: "1" },
        { text: "Authors", type: "2" },
        // { text: "Materials", type: "3" }
        // { text: "Recommended", link: "recommended" }
    ])
    const fetchData = async (is_scroll = false, end = 0) => {
        if (serachValue) {
            if (searchType == "1" && (!videosref.current || is_scroll)) {
                setDataIsLoaded(false)
                setFoundResults(false)
                axios.post(`${env.SERVER_REQUEST_PATH}search?page=${pageCount}`, { search_word: serachValue })
                    .then((response) => {
                        let new_vids = response.data.data
                        let vid = videosref.current
                        if (is_scroll) {
                            let all_v = []
                            all_v.push(...vid)
                            all_v.push(...new_vids)
                            videosref.current = all_v
                            scrolledCount.current = all_v.length
                            setVideos(all_v)
                            if (all_v.length > 0)
                                setFoundResults(true)
                        }
                        else {
                            scrolledCount.current = new_vids.length
                            videosref.current = new_vids;
                            setVideos(new_vids)
                            if (new_vids.length > 0)
                                setFoundResults(true)
                        }
                        setDataIsLoaded(true)

                        totalCount.current = response.data.meta ? response.data.meta.totalCount : 0

                    }).catch(err => {
                        showBoundary(err)
                    })
            }
            else if (searchType == "2" && (users.length <= 0 || is_scroll)) {
                setDataIsLoaded(false)
                setFoundResults(false)
                axios.post(`${env.SERVER_REQUEST_PATH}searchusers?page=${pageCount}`, { search_word: serachValue })
                    .then((response) => {
                        let urs = response.data.data
                        setusers(urs);

                        setUsertotalcount(response.data.meta.totalCount)
                        setDataIsLoaded(true)
                        if (urs.length > 0)
                            setFoundResults(true)

                    }).catch(err => {
                        showBoundary(err)

                    })
            }
            else {
                if (videosref.current?.length > 0 || users?.length > 0)
                    setFoundResults(true)
            }
            // else if (searchType == "3" && (!videos_material_ref.current || is_scroll)) {
            //     setDataIsLoaded(false)
            //     setFoundResults(false)
            //     axios.post(`${env.SERVER_REQUEST_PATH}searchbymaterial?page=${pageMCount}`, { search_word: serachValue })
            //         .then((response) => {
            //             let new_vids = response.data.data
            //             let vid = videos_material_ref.current
            //             if (is_scroll) {
            //                 let all_v = []
            //                 all_v.push(...vid)
            //                 all_v.push(...new_vids)
            //                 videos_material_ref.current = all_v
            //                 scrolledMCount.current = all_v.length
            //                 setVideos(all_v)
            //                 if (all_v.length > 0)
            //                     setFoundResults(true)
            //             }
            //             else {
            //                 scrolledMCount.current = new_vids.length
            //                 videos_material_ref.current = new_vids;
            //                 setVideos(new_vids)
            //                 if (new_vids.length > 0)
            //                     setFoundResults(true)
            //             }
            //             setDataIsLoaded(true)

            //             totalMCount.current = response.data.meta ? response.data.meta.totalCount : 0

            //         }).catch(err => {
            //             showBoundary(err)
            //         })
            // }
        }

    }

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
        if (searchType == "1" && bottom && scrolledCount.current < totalCount.current) {
            setpageCount(page => page + 1)
        }
        // else if (searchType == "3" && bottom && scrolledCount.current < totalCount.current) {
        //     setpageMCount(page => page + 1)
        // }
    };
    useEffect(() => {
        if (pageCount > 1)
            fetchData(videosref.current?.length);
    }, [pageCount])

    useEffect(() => {
        if (pageMCount > 1)
            fetchData(videos_material_ref.current?.length);
    }, [pageMCount])

    useEffect(() => {
        // let search_type = searchParams.get("t")
        let search_word = searchParams.get("s")
        if (search_word != serachValue) {
            setserachValue(search_word)
            // setsearchType(search_type)
            videosref.current = null
            videos_material_ref.current = null
            setVideos()
            setusers([])
        }
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location]);

    useEffect(() => {
        if (serachValue)
            fetchData();

    }, [searchType, serachValue])
    const getMoreVideos = async () => {
        if (scrolledCount.current < totalCount.current)
            await fetchData(videosref.current?.length);
    };

    return (
        <div className='d-flex flex-column w-100'>
            <Header userdata={userDetails} catchErrors={(err) => { showBoundary(err) }} />

            <div className='mt-5 px-md-5 px-3 py-5' style={{ "min-height": "90vh" }}>
                <h5 className='fw-bold'>Search Results of "{searchParams.get("s")}"</h5>

                <Navbar loadData={(newType) => {
                    setsearchType(newType)
                }} feeds={searchtabs} currentType={searchType} />

                {foundResults && <>

                    <div hidden={searchType == "1" ? '' : 'hidden'}>

                        <FeedsGrid cards={videosref.current}
                            refreshVideos={() => { fetchData() }}
                            catchErrors={(err) => showBoundary(err)}>
                        </FeedsGrid>
                    </div>


                    <div hidden={searchType == '2' ? '' : 'hidden'} >
                        <UserGrid users={users} updateUsers={(index, following) => {
                            let u = [...users]
                            u[index].isFollowing = following
                            setusers(u)
                        }} />
                    </div>

                    {/* <div hidden={searchType == "3" ? '' : 'hidden'}>
                        <FeedsGrid cards={videos_material_ref.current}
                            refreshVideos={() => { fetchData() }}
                            catchErrors={(err) => showBoundary(err)}>
                        </FeedsGrid>
                    </div> */}


                </>}
                {!foundResults && DataIsLoaded &&
                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <h5 className='mt-5 fw-bold px-5'>No Search Results for {searchParams.get("s")}</h5>
                    </div>
                }
                {!foundResults && !DataIsLoaded &&
                    <div className='loading-search d-flex justify-content-center align-items-center'>
                        <PropagateLoader color="#B9AFF7" width={150} /></div>
                }
            </div>

            <MiniFooter />
        </div>
    );
}

export default SearchResult
