import FormInput from '../../SharedComponents/js/FormInput'
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import TermCondition from './TermCondition';
import ResearchInterest from './ResearchInterest';
const env = require('../../config.env')

const SignUpStep2 = (props) => {
    const { onChange } = props;
    const [countries, getcountries] = useState([])

    useEffect(() => {
        axios.get(env.SERVER_REQUEST_PATH + 'countries')
            .then((response) => {
                let data = response.data.data
                getcountries(data)
            }).catch(err => {
                // props.handleErrors(err)
                console.error(`Error: ${err}`)
            })
    }, [])



    return (
        <div id="step2Container">
            <form autocomplete="off">
                <h1 className="auth-form-title text-center" id="step3HeaderTitle">Complete your profile</h1>

                <div className='d-flex justify-content-center w-100'>
                    <p className='error-msg'
                        hidden={props.hasError ? '' : 'hidden'}>{props.errMsg}</p>
                </div>

                <div className="auth-form-details mt-2" id="step3Details">

                    <FormInput type={"text"} name="Position" label="Position" placeholder="Position"
                        required='required' onChange={onChange}
                    />
                    <FormInput type={"text"} name="Affiliation" label="Affiliation" placeholder="Affiliation"
                        required='required' onChange={onChange}
                    />
                    <ResearchInterest selected={(interests) => {
                        onChange("interests", interests)
                    }} />

                    <label className="form-label input-label">Country/Region</label>
                    <Form.Select className='select-box' name='country' onChange={onChange} aria-label="Country/Region">
                        {countries.map((item) => {
                            return (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Select>
                    <TermCondition onChange={onChange} />
                </div>
                {/* <Button type="submit" className={"w-100 mb-3"}>Complete</Button> */}
            </form>
        </div>
    )
}

export default SignUpStep2