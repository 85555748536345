import React, { useEffect, useState, useRef } from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useErrorBoundary } from "react-error-boundary";
import axios from 'axios';
import { AiFillPlusCircle, AiFillCheckCircle } from 'react-icons/ai'
import '../css/PublicProfile.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Header from '../../Main/Components/Header'
import Footer from '../../SharedComponents/js/Footer'
import FeedsGrid from '../Components/Main/Feeds/FeedsGrid';
import { useStateValue } from "../../index";
import { BarLoader, ClipLoader, PropagateLoader } from 'react-spinners';
import FingerLeftMenu from '../Components/Main/FingerLeftMenu';
import PlaylistGrid from '../Components/Main/Playlists/AudioPlaylistFeedGrid';
import MiniFooter from '../../SharedComponents/js/Mini-Footer';
import { BsChat, BsCheck, BsExclamationCircle, BsExclamationCircleFill, BsRocketTakeoff } from 'react-icons/bs';
import Navbar from '../../SharedComponents/js/Navbar';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import Publish from '../../PDFGeneration/Components/Publish';
const helper = require('../../utilities/helpers')
const env = require('../../config.env')

const PublicProfile = (props) => {
    const state_obj = useLocation();
    const { id } = useParams()
    const [user, setuser] = useState()
    const [pagePCount, setpagePCount] = useState(1)
    const [pagePrCount, setpagePrCount] = useState(1)
    const [pageSCount, setpageSCount] = useState(1)
    const [pageVCount, setpageVCount] = useState(1)
    const [pageVpCount, setpageVpCount] = useState(1)
    const [pagePenCount, setpagePenCount] = useState(1)
    const [userVideos, setuserVideos] = useState()
    const [members, setmembers] = useState([])
    const [admin, setAdmin] = useState()
    const [isRequestSent, setisRequestSent] = useState(false)
    const videosref = useRef();
    const privatevideosref = useRef();
    const savedvideosref = useRef();
    const playlistsref = useRef();
    const v_playlistsref = useRef()
    const pendingvideosref = useRef()
    const private_ref = useRef()
    const saved_ref = useRef()
    const selected_video_ref = useRef()
    const [isShow, set_isShow] = useState(false)

    const [type, set_type] = useState("1")
    const [feeds,] = useState([
        { text: "Videos", type: "1" },
        // { text: "Playlists", type: "2" }
        // { text: "Recommended", link: "recommended" }
    ])
    const [prvfeeds,] = useState([
        { text: "Videos", type: "1" },
        // { text: "Playlists", type: "2" },
        { text: "Private", type: "3" },
        { text: "Saved", type: "4" },
        { text: "Pending", type: "5" },
        // { text: "Recommended", link: "recommended" }
    ])

    const totalVCount = useRef()
    const totalVpCount = useRef()
    const totalPCount = useRef()
    const totalPrCount = useRef()
    const totalSCount = useRef()
    const totalPenCount = useRef()

    const scrolledVCount = useRef()
    const scrolledVpCount = useRef()
    const scrolledPCount = useRef()
    const scrolledPrCount = useRef()
    const scrolledSCount = useRef()
    const scrolledPenCount = useRef()

    const [activeTab, setActiveTab] = useState(1)
    const [userDetails, dispatch] = useStateValue();
    const [isFollowed, setisFollowed] = useState(false)
    const [refreshData, setrefreshdata] = useState()
    const [is_private, set_isPrivate] = useState(false)
    const { showBoundary } = useErrorBoundary();
    const navigate = useNavigate()

    useEffect(() => {
        const user_id = state_obj?.state?.hasOwnProperty("id") ? state_obj.state?.id ? state_obj.state?.id : null : state_obj?.state ? state_obj?.state : null
        axios.post(`${env.SERVER_REQUEST_PATH}getUserProfile`, { userId: user_id, username: id })
            .then((response) => {
                setuser(response.data)
                setisFollowed(response.data.isFollowing > 0 ? true : false)
                set_isPrivate(response.data.ismyprofile)
                if (!state_obj?.state?.role) {
                    state_obj.state = { id: response.data.user_id, role: response.data.ismyprofile ? 'aspublic' : '' }
                }
            }).catch(err => {
                showBoundary(err)
                console.error(`Error: ${err}`)
            })

    }, [id])
    useEffect(() => {
        const user_id = state_obj?.state?.hasOwnProperty("id") ? state_obj.state?.id ? state_obj.state?.id : null : state_obj?.state ? state_obj?.state : null
        axios.post(`${env.SERVER_REQUEST_PATH}getgroupmembers`, { userId: user_id, username: id })
            .then((response) => {
                setmembers(response.data)
                let groupAdmin
                if (user_id)
                    groupAdmin = response.data.find(x => x.id == user_id)
                else
                    groupAdmin = response.data.find(x => x.username == id)

                setAdmin(groupAdmin)
            }).catch(err => {
                showBoundary(err)
                console.error(`Error: ${err}`)
            })

    }, [id])
    const fetchData = (grid_type) => {
        switch (grid_type) {
            case '1':
                fetchVideos(true)
                return;
            case '2':
                fetchplaylists(true)
                return;
            case '3':
                fetchPrivateVideos(true)
                return;
            case '4':
                fetchSavedVideos(true)
                return;
            case '5':
                fetchPendingVideos(true)
                return;
        }

    }
    const fetchVideos = async (is_tab_click, end = 0) => {
        const user_id = state_obj?.state?.hasOwnProperty("id") ? state_obj.state?.id ? state_obj.state?.id : null : state_obj?.state ? state_obj?.state : null
        if ((is_tab_click && !videosref.current) || !is_tab_click)
            axios.post(`${env.SERVER_REQUEST_PATH}getUserVideos?page=${pageVCount}`, { userId: user_id, username: id })
                .then((response) => {
                    let new_vids = response.data.data
                    let vid = videosref.current
                    let all_v = []
                    if (vid)
                        all_v.push(...vid)
                    all_v.push(...new_vids)
                    videosref.current = all_v
                    setuserVideos(all_v)
                    totalVCount.current = response.data.meta ? response.data.meta.totalCount : 0

                    scrolledVCount.current = all_v.length
                }).catch(err => {
                    showBoundary(err)
                    console.error(`Error: ${err}`)
                })
    }
    const fetchPrivateVideos = async (is_tab_click, end = 0) => {
        const user_id = state_obj?.state?.hasOwnProperty("id") ? state_obj.state?.id ? state_obj.state?.id : null : state_obj?.state ? state_obj?.state : null
        if ((is_tab_click && !privatevideosref.current) || !is_tab_click)
            axios.post(`${env.SERVER_REQUEST_PATH}getUserPrivateVideos?page=${pagePrCount}`, { userId: user_id, username: id })
                .then((response) => {
                    let new_vids = response.data.data
                    let vid = privatevideosref.current
                    let all_v = []
                    if (vid)
                        all_v.push(...vid)
                    all_v.push(...new_vids)
                    privatevideosref.current = all_v
                    setuserVideos(all_v)
                    totalPrCount.current = response.data.meta ? response.data.meta.totalCount : 0

                    scrolledPrCount.current = all_v.length
                }).catch(err => {
                    showBoundary(err)
                    console.error(`Error: ${err}`)
                })
    }
    const fetchSavedVideos = async (is_tab_click, end = 0) => {
        const user_id = state_obj?.state?.hasOwnProperty("id") ? state_obj.state?.id ? state_obj.state?.id : null : state_obj?.state ? state_obj?.state : null
        if ((is_tab_click && !savedvideosref.current) || !is_tab_click)
            axios.post(`${env.SERVER_REQUEST_PATH}getUserSavedVideos?page=${pageSCount}`, { userId: user_id, username: id })
                .then((response) => {
                    let new_vids = response.data.data
                    let vid = savedvideosref.current
                    let all_v = []
                    if (vid)
                        all_v.push(...vid)
                    all_v.push(...new_vids)
                    savedvideosref.current = all_v
                    setuserVideos(all_v)
                    totalSCount.current = response.data.meta ? response.data.meta.totalCount : 0

                    scrolledSCount.current = all_v.length
                }).catch(err => {
                    showBoundary(err)
                    console.error(`Error: ${err}`)
                })
    }
    const fetchPendingVideos = async (is_tab_click, end = 0) => {
        // const user_id = state_obj?.state?.hasOwnProperty("id") ? state_obj.state?.id ? state_obj.state?.id : null : state_obj?.state ? state_obj?.state : null
        if ((is_tab_click && !pendingvideosref.current) || !is_tab_click)
            axios.get(`${env.SERVER_REQUEST_PATH}pendingvideos?page=${pageVCount}`)
                .then((response) => {
                    let new_vids = response.data.data
                    let vid = pendingvideosref.current
                    let all_v = []
                    if (vid)
                        all_v.push(...vid)
                    all_v.push(...new_vids)
                    pendingvideosref.current = all_v
                    setuserVideos(all_v)
                    totalPenCount.current = response.data.meta ? response.data.meta.totalCount : 0

                    scrolledPenCount.current = all_v.length
                }).catch(err => {
                    showBoundary(err)
                    console.error(`Error: ${err}`)
                })
    }

    const fetchplaylists = async (is_tab_click, end = 0) => {
        const user_id = state_obj?.state?.hasOwnProperty("id") ? state_obj.state?.id ? state_obj.state?.id : null : state_obj?.state ? state_obj?.state : null
        let headers = { user_id: user_id, username: id }
        if ((is_tab_click && !v_playlistsref.current) || !is_tab_click)
            axios.get(`${env.SERVER_REQUEST_PATH}getUserPublicVideoplaylists?page=${pageVpCount}`,
                { headers: headers })
                .then((response) => {
                    let new_vplaylists = response.data.data
                    let vplaylist = v_playlistsref.current
                    let all_v = []
                    if (vplaylist)
                        all_v.push(...vplaylist)
                    all_v.push(...new_vplaylists)
                    v_playlistsref.current = all_v
                    // setuserVideos(all_v)
                    totalVpCount.current = response.data.meta ? response.data.meta.totalCount : 0

                    scrolledVpCount.current = all_v.length
                }).catch(err => {
                    showBoundary(err)
                    console.error(`Error: ${err}`)
                })
    }
    const fetchAudios = async (is_tab_click, end = 0) => {
        const user_id = state_obj?.state?.hasOwnProperty("id") ? state_obj.state?.id ? state_obj.state?.id : null : state_obj?.state ? state_obj?.state : null
        let headers = { user_id: user_id, username: id, PAGE_SIZE: env.SCROLLED_PAGE_SIZE, status: true }
        if ((is_tab_click && !playlistsref.current) || !is_tab_click)
            axios.get(`${env.SERVER_REQUEST_PATH}audioplaylists?page=${pagePCount}`, { headers: headers })
                .then((response) => {
                    let new_list = response.data.data
                    let playlist = playlistsref.current
                    let all_p = []
                    if (playlist)
                        all_p.push(...playlist)
                    all_p.push(...new_list)
                    playlistsref.current = all_p
                    // setuserVideos(all_p)
                    totalPCount.current = response.data.meta ? response.data.meta.totalCount : 0
                    scrolledPCount.current = all_p.length
                }).catch(err => {
                    showBoundary(err)
                    console.error(`Error: ${err}`)
                })
    }
    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
        if (activeTab == 1) {
            if (bottom && scrolledVCount.current < totalVCount.current) {
                setpageVCount(page => page + 1)
            }
        }
        else if (activeTab == 2) {
            if (bottom && scrolledVpCount.current < totalVpCount.current) {
                setpageVpCount(page => page + 1)
            }
        }
        else if (activeTab == 3) {
            if (bottom && scrolledPrCount.current < totalPrCount.current) {
                setpagePrCount(page => page + 1)
            }
        }
        else if (activeTab == 4) {
            if (bottom && scrolledSCount.current < totalSCount.current) {
                setpageSCount(page => page + 1)
            }
        }
        else if (activeTab == 5) {
            if (bottom && scrolledPenCount.current < totalPenCount.current) {
                setpagePenCount(page => page + 1)
            }
        }
        else {
            if (bottom && scrolledPCount.current < totalPCount.current) {
                setpagePCount(page => page + 1)
            }
        }
    };
    useEffect(() => {
        videosref.current = null;
        privatevideosref.current = null;
        savedvideosref.current = null;
        playlistsref.current = null;
        v_playlistsref.current = null
        pendingvideosref.current = null

        fetchVideos();
        // fetchAudios()
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [id]);
    useEffect(() => {
        if (pageVCount > 1)
            fetchVideos();
    }, [pageVCount])
    useEffect(() => {
        if (pageVpCount > 1)
            fetchplaylists();
    }, [pageVpCount])
    useEffect(() => {
        if (pagePrCount > 1)
            fetchPrivateVideos();
    }, [pagePrCount])
    useEffect(() => {
        if (pageSCount > 1)
            fetchSavedVideos();
    }, [pageSCount])
    useEffect(() => {
        if (pagePenCount > 1)
            fetchPendingVideos();
    }, [pagePenCount])
    useEffect(() => {
        if (pagePCount > 1)
            fetchAudios();
    }, [pagePCount])
    // useEffect(() => {
    //     const user_id = state_obj?.state?.id ? state_obj.state?.id : state_obj?.state
    //     let headers = { username: id }
    //     axios.get(`${env.SERVER_REQUEST_PATH}isFollowed/${user_id ? user_id : -1}`, { headers: headers })
    //         .then((response) => {
    //             setisFollowed(response.data.isFollowed)
    //         }).catch(err => {
    //             showBoundary(err)
    //             console.error(`Error: ${err}`)
    //         })

    // }, [refreshData])
    useEffect(() => {
        if (user && user?.group_id)
            if (!state_obj?.state?.role) {
                axios.get(`${env.SERVER_REQUEST_PATH}isJoinRequest/${user?.group_id}`)
                    .then((response) => {
                        setisRequestSent(response.data.isSent)
                    }).catch(err => {
                        showBoundary(err)
                        console.error(`Error: ${err}`)
                    })
            }

    }, [refreshData, user])

    const unfollow = () => {
        const user_id = state_obj?.state?.id ? state_obj.state?.id : state_obj?.state
        let headers = { username: id }
        setisFollowed(false)
        axios.get(`${env.SERVER_REQUEST_PATH}unfollow/${user_id ? user_id : -1}`, { headers: headers })
            .then((response) => {
                // setrefreshdata(Math.random())
            }).catch(err => {
                showBoundary(err)
                setisFollowed(true)
            })
    }
    const follow = () => {
        const user_id = state_obj?.state?.id ? state_obj.state?.id : state_obj?.state
        let headers = { username: id }
        setisFollowed(true)

        axios.get(`${env.SERVER_REQUEST_PATH}follow/${user_id ? user_id : -1}`, { headers: headers })
            .then((response) => {
                // setrefreshdata(Math.random())
            }).catch(err => {
                showBoundary(err)
                setisFollowed(false)
            })
    }
    const joinGroup = () => {
        axios.post(`${env.SERVER_REQUEST_PATH}joingroup`,
            { group_id: user?.group_id, username: user?.username })
            .then((response) => {
                setrefreshdata(Math.random())
            }).catch(err => {
                showBoundary(err)
                console.error(`Error: ${err}`)
            })

    }
    const handleClose = () => set_isShow(false)
    const publishVideo = (title, descr, taggedUsers) => {
        handleClose()
        let req_data = {
            title: title, descr: descr, is_author: true,
            video_id: selected_video_ref.current.id,
            video_path: selected_video_ref.current?.uri,
            thumb_img: selected_video_ref.current?.thumb_url,
            taggedUsers: taggedUsers?.length > 0 ? taggedUsers : []
        }
        axios.post(env.SERVER_REQUEST_PATH + 'publishOrSave', req_data)
            .then((response) => {

                let status = response.status
                if (status == 200) {
                    // restErrors()
                    // set_refresh_grid(true)
                    // navigate(`/videos/${VideoId}`, { state: { is_private: response.data.is_private } })
                }
                // setIsLoading(false)

            }).catch(err => {
                // catchErrors(err)
                // set_stop_process(true)
                // console.error(`Error: ${err}`)
            })

    }

    return (
        <div>
            <div className='d-flex flex-column w-100'>
                <Header userdata={userDetails} catchErrors={(err) => { showBoundary(err) }} />
                <div className='pub-profile-content w-100'>
                    <div>
                        <div className='img-container'>
                            <img src={`${env.SERVER_MEDIA_PATH + 'cover.png'}`} />
                        </div>

                        <div className='d-flex flex-column flex-lg-row w-100'>
                            <div className='profile-col d-flex'>
                                {user && <div className='d-flex flex-column px-lg-5 px-md-4 px-2 align-items-center w-100'>
                                    <div className='profile-img-container'>
                                        {user &&
                                            user?.profile_img &&
                                            <img src={env.SERVER_REQUEST_PATH + user?.profile_img}
                                                className='profile-pub-img' rounded />
                                        }
                                        {user && !user?.profile_img && <FaUserCircle size={150} color='6e757c' />}

                                    </div>

                                    <div className='d-flex flex-column align-items-center my-3'>

                                        {user && <span className='pub-name'>
                                            {user.firstname + ' '}
                                            {user.lastname ? user.lastname : ''}
                                        </span>}

                                        {user && <p className='my-0 mt-0 font-style fontcolor'>
                                            @{user.username}
                                        </p>
                                        }

                                        {user && <p className='my-0 mt-0 font-style fontcolor'>
                                            {user.country_name}
                                        </p>
                                        }

                                        <div className='mt-2' style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
                                            <div className='follow-style'>
                                                <p className='text-center'><span className='follow-num-style'>{user ? helper.kFormatter(user.followers) : 0}</span> Followers</p>
                                            </div>
                                            <div className='follow-style'>
                                                <p className='text-center'><span className='follow-num-style'>{user ? helper.kFormatter(user.following) : 0}</span> Following</p>
                                            </div>
                                        </div>

                                        {user && <p className='pub-aff mb-1 text-center'>{user.affiliation}</p>}

                                        <p className='mb-0 text-center'>{user ? user.bio : ''}</p>

                                    </div>

                                    <div className='d-flex align-items-center justify-content-center gap-3'>
                                        <div className='d-flex flex-column align-items-center'>
                                            <span>-</span>
                                            <span title='Coming Soon' className='fontcolor'>Citations</span>
                                        </div>
                                        <div className='separator'></div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <span>-</span>
                                            <span title='Coming Soon' className='fontcolor'>h-index</span>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-end align-items-end mt-4 gap-4'>
                                        <a href={user ? user.researchgate_url : ''}
                                            target="_blank"
                                        >
                                            <img
                                                title='ResearchGate' width={30} src={`${env.SERVER_MEDIA_PATH + 'google.png'}`} />
                                        </a>
                                        <a href={user ? user.linkedin_url : ''}
                                            target="_blank"
                                        >
                                            <img title='LinkedIn' width={30} src={`${env.SERVER_MEDIA_PATH + 'linkedin.png'}`} />
                                        </a>
                                    </div>

                                    {!is_private &&
                                        <div className='d-flex justify-content-end align-items-end mt-4 w-100'>
                                            {isFollowed && <Button
                                                className='me-2 w-100' variant="primary" size="sm" disabled={state_obj?.state?.role ? 'disabled' : ''}
                                                onClick={unfollow}>Following
                                                <BsCheck size={20}></BsCheck>
                                            </Button>
                                            }

                                            {!isFollowed && <Button className='me-2 w-100' size="sm" variant="outline-primary"
                                                disabled={state_obj?.state?.role ? 'disabled' : ''} onClick={follow} >Follow
                                            </Button>}

                                            <Button title='Coming Soon' className='video-btn' variant="outline-primary" size="sm"><BsChat color='#5644DE' /></Button>
                                        </div>
                                    }
                                    {is_private &&
                                        <Button className='me-2 mt-4 w-100' size="sm" variant="outline-primary"
                                            onClick={() => { navigate("/edit-profile/" + id) }} >Edit profile
                                        </Button>
                                    }
                                    {is_private &&
                                        <div className='now-plan text-center p-4 mt-4'>
                                            <p className='mb-2'>You are on a free plan!</p>
                                            <span>Upgrade now to Pro plan and enjoy all of our features.</span>
                                            <Button className='mt-3 w-100' size="sm" variant="primary" onClick={follow} >Upgrade now <BsRocketTakeoff size={15} className='ms-2' /></Button>
                                        </div>
                                    }
                                </div>}
                                {!user && <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                                    <PropagateLoader color="#B9AFF7" /></div>}
                            </div>

                            <div className='d-flex flex-column w-100' style={{ "flex-grow": 1, "min-width": 0 }}>

                                <Navbar loadData={(newType) => {
                                    set_type(newType)
                                    setActiveTab(newType)
                                    fetchData(newType)
                                }} feeds={is_private ? prvfeeds : feeds} currentType={type} />
                                <div hidden={activeTab == 1 ? '' : 'hidden'} className='w-100'>
                                    <FeedsGrid cards={videosref.current}
                                        refreshVideos={() => { fetchVideos() }}
                                        catchErrors={(err) => showBoundary(err)}
                                        // grid_size={user?.group_name ? 4 : 5}
                                        grid_size={4}>
                                    </FeedsGrid>
                                </div>
                                <div hidden={activeTab == 2 ? '' : 'hidden'} className='w-100'>
                                    <FeedsGrid cards={v_playlistsref.current}
                                        playlist={true}
                                        // grid_size={user?.group_name ? 4 : 5}
                                        grid_size={4}
                                        is_private={state_obj?.state ? state_obj?.state.is_private : false}
                                        refreshVideos={() => { fetchplaylists() }}
                                        catchErrors={(err) => showBoundary(err)}>
                                    </FeedsGrid>
                                </div>
                                <div hidden={activeTab == 3 ? '' : 'hidden'} className='w-100'>
                                    <div className='w-100 px-3 pb-3'>
                                        <div className='w-100 private-alert d-flex justify-content-center align-items-center p-2'>
                                            <BsExclamationCircle className='me-2' size={18} color='#000' />
                                            This is only visible to you since you’re not the author of those researches.
                                        </div>
                                    </div>
                                    <FeedsGrid cards={privatevideosref.current}
                                        publishvideo={(v_data) => {
                                            selected_video_ref.current = v_data
                                            set_isShow(true)
                                        }}
                                        refreshVideos={() => { fetchPrivateVideos() }}
                                        catchErrors={(err) => showBoundary(err)}
                                        // grid_size={user?.group_name ? 4 : 5}
                                        grid_size={4}>
                                    </FeedsGrid>
                                </div>
                                <div hidden={activeTab == 4 ? '' : 'hidden'} className='w-100'>
                                    <FeedsGrid cards={savedvideosref.current}
                                        refreshVideos={() => { fetchSavedVideos() }}
                                        catchErrors={(err) => showBoundary(err)}
                                        // grid_size={user?.group_name ? 4 : 5}
                                        grid_size={4}>
                                    </FeedsGrid>
                                </div>
                                <div hidden={activeTab == 5 ? '' : 'hidden'} className='w-100'>
                                    <FeedsGrid cards={pendingvideosref.current}
                                        publishvideo={(v_data) => {
                                            selected_video_ref.current = v_data
                                            set_isShow(true)
                                        }}
                                        refreshVideos={() => { fetchPendingVideos() }}
                                        catchErrors={(err) => showBoundary(err)}
                                        // grid_size={user?.group_name ? 4 : 5}
                                        grid_size={4}>
                                    </FeedsGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MiniFooter />
            </div>
            <div>
                <Modal className='publish-modal' size="lg" show={isShow} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Publish your video</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Publish
                            VideoData={{
                                video_path: selected_video_ref.current?.uri,
                                thumb_img: selected_video_ref.current?.thumb_url,
                                subtitle: selected_video_ref.current?.subtitle
                            }}
                            viewer_type="tokens"
                            is_author={true} is_share={true}
                            doc_title={selected_video_ref.current?.VideoTitle}
                            saveVideo={publishVideo}
                        />
                    </Modal.Body>

                </Modal>
            </div>
        </div>
    )
}

export default PublicProfile
