import React, { useRef, useState, useEffect } from "react";
import "../../css/ShareVideo.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { BiSearch } from 'react-icons/bi';
import Alert from 'react-bootstrap/Alert'
import axios from "axios";
const env = require('../../../config.env')

const ShareVideo = (props) => {
    const linkRef = useRef(null);
    const searchRef = useRef(null);
    const [show, setShow] = useState(props.showModal);
    const [searchValue, setSearchValue] = useState('');
    const [friends, setFriends] = useState([])
    const [ret_msg, setret_msg] = useState('')
    const [has_msg, sethas_msg] = useState(false)
    useEffect(() => {
        setShow(props.showModal)
    }, [props.showModal])

    const handleClose = () => {
        setShow(false)
        props.closeModal()
    };
    const debounce = (func, delay = 3000) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }

    const copyVideo = async () => {
        let copied_text = linkRef.current.value
        navigator.clipboard.writeText(copied_text)
    }

    const selectFriend = (friend) => {
        searchRef.current.value = friend.username;
        setSearchValue();
        setFriends([])
    }


    const handleChange = (event) => {
        setSearchValue(event.target.value);
    }

    const tag_user = () => {
        props.share_loading(true)
        axios.post(`${env.SERVER_REQUEST_PATH}tag`, {
            username: searchRef.current.value, video_id: props.video_id,
            link: props.video_url
        })
            .then((response) => {
                props.share_loading(false)
                showError(response.data.msg)
            }).catch(err => {
                props.share_loading(false)
                console.error(`Error: ${err}`)
            })
    }
    const showError = (msg) => {
        setret_msg(msg)
        sethas_msg(true)
        setTimeout(() => {
            hideError();
            handleClose()
        }, 3000);
    }
    const hideError = () => {
        setret_msg('')
        sethas_msg(false)
    }
    useEffect(() => {
        if (!searchValue) return;
        const debouncedSave = debounce(() => {
            const url = `${env.SERVER_REQUEST_PATH}searchfortag`; // Replace with your local URL
            const data = {
                username: searchValue
            };
            axios.post(url, data)
                .then((response) => {
                    setFriends(response.data.users)
                })
                .catch(error => {
                    // Handle the error
                    console.error('Error:', error);
                });


        }, 500);
        debouncedSave(searchValue);
    }, [searchValue]);

    return (
        <div>
            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header className="share-modal" closeButton>
                    <Modal.Title>
                        <div className="d-flex flex-column ps-2 pt-2 text-left">
                            <p className='header-weight-bold'>Share your video with others!</p>
                            <p className='header-fineText'>Invite others to watch your video!</p>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box-outline" >
                        <InputGroup className="copy-input-style mb-3">
                            <Form.Control
                                placeholder="video-link"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                value={props.video_url}
                                readOnly
                                ref={linkRef}
                            />
                            <Button className="ms-1 btn-share-color" variant="primary" id="button-addon2" onClick={copyVideo}>
                                Copy
                            </Button>
                        </InputGroup>
                        <p>
                            Invite friends
                        </p>
                        <InputGroup className="search-input-style mb-3">
                            <InputGroup.Text id="basic-addon1">
                                <BiSearch />
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="search"
                                aria-label="search"
                                aria-describedby="basic-addon2"
                                ref={searchRef}
                                onChange={handleChange}
                                value={searchValue}
                            />
                            <Button className="ms-1 btn-share-color" onClick={tag_user}
                                variant="primary" id="button-addon2" disabled={props.isLoading ? 'disabled' : ''} >
                                Send
                            </Button>
                        </InputGroup>
                        {friends?.length > 0 &&
                            <div className="users-outline" >
                                {friends.map((friend) => {
                                    return (
                                        <div onClick={() => { selectFriend(friend) }}>
                                            <div className="user-result" onClick={() => {

                                            }} key={friend.id}>
                                                <img src={friend.profile_img ? env.SERVER_REQUEST_PATH + friend.profile_img : ''} className="dot" />
                                                <p className='user-name'>
                                                    {friend.name}
                                                </p>
                                                <p className='username-fineText user-tag'>
                                                    @{friend.username}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>}

                        <Alert className="msg_alert" variant="primary" hidden={has_msg ? '' : 'hidden'}>
                            <Alert.Heading>{ret_msg}</Alert.Heading>
                        </Alert>
                    </div>
                </Modal.Body>

            </Modal>
        </div >
    );
}

export default ShareVideo;
