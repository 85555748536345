import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../../../css/Search.css'
const Search = () => {
    const navigate = useNavigate()
    const search = (e) => {
        // if (e.key === 'Enter') {
        let page = window.location.pathname.split('/')[1].split('?')[0]
        if (page != "search")
            navigate(`/search?s=${e.target.elements.s.value}`)
        // }
    }
    return (
        <form onSubmit={search} autocomplete="off" className="search-form col-12 col-lg-auto" >
            <input
                type="search"
                // onKeyDown={search}
                className="form-control" name="s"
                placeholder="Search for Content, Authors, Materials, ..etc" aria-label="Search" />
        </form>
    )
}

export default Search
