import React, { useState, useCallback, useEffect } from "react";
import { Stack, Button, Form, Alert, InputGroup, Modal } from "react-bootstrap";
import axios from 'axios'
import PasswordForm from "./PasswordForm";
// import '../css/PasswordForm.css'
const env = require('../../../../config.env')
const UserForm = (props) => {
    const [countries, getcountries] = useState([])
    const [firstname, setfirstname] = useState('')
    const [lastname, setlastname] = useState('')
    const [email, setemail] = useState('')
    const [username, setusername] = useState('')
    const [affiliation, setaffiliation] = useState('')
    const [position, setposition] = useState('')
    const [linkedin, setlinkedin] = useState('')
    const [researchgate, setresearchgate] = useState('')
    const [selected_country, setselected_country] = useState(props.user.country_id)
    const [bio, setbio] = useState('')
    const [cancelFlag, setcancelFlag] = useState(false)
    const [alert_msg, setalert_msg] = useState('')
    const [error, seterror] = useState(false)
    const [alert_show, setalert_show] = useState("hidden")
    const [showChangePassword, setShowChangePassword] = useState(false);

    useEffect(() => {
        axios.get(env.SERVER_REQUEST_PATH + 'countries')
            .then((response) => {
                let data = response.data.data
                getcountries(data)
            }).catch(err => {
                // props.handleErrors(err)
                console.error(`Error: ${err}`)
            })
    }, [])


    const handleChangePasswordClose = () => setShowChangePassword(false);
    const handleChangePasswordShow = () => setShowChangePassword(true);

    const showAlert = (msg) => {
        setalert_msg(msg)
        setalert_show("")
        setTimeout(() => {
            hideAlert();
        }, 3000);
    }
    const hideAlert = () => {
        setalert_msg('')
        setalert_show("hidden")
    }
    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }
    const onInputChange = (event) => {
        let changedInput = event.target.name
        let changedValue = event.target.value

        switch (changedInput) {
            case 'firstname':
                setfirstname(changedValue)
                return
            case 'lastname':
                setlastname(changedValue)
                return
            case 'email':
                setemail(changedValue)
                return
            case 'username':
                setusername(changedValue)
                return
            case 'affiliation':
                setaffiliation(changedValue)
                return
            case 'position':
                setposition(changedValue)
                return
            case 'linkedin':
                setlinkedin(changedValue)
                return
            case 'researchgate':
                setresearchgate(changedValue)
                return
            case 'country':
                setselected_country(changedValue)
                return
            case 'bio':
                setbio(changedValue)
                return
        }

    }
    let optimisedHandleChange = useCallback(
        debounce(onInputChange, 500)
        ,
        []
    );
    const resetState = () => {
        setfirstname('')
        setlastname('')
        setemail('')
        setusername('')
        setaffiliation('')
        setbio('')
    }
    const onsubmit = (e) => {

        e.preventDefault()
        if (!cancelFlag) {

            let user = {
                firstname: firstname,
                lastname: lastname,
                email: email,
                username: username,
                bio: bio,
                affiliation: affiliation,
                position: position,
                linkedin: linkedin,
                researchgate: researchgate,
                country_id: selected_country
            }
            axios.post(env.SERVER_REQUEST_PATH + 'updateuserinfo', user)
                .then((response) => {
                    let status = response.status
                    if (status == 200) {
                        if (response.data.iscorrect) {
                            seterror(false)
                            e.target.reset()
                            resetState()
                            props.onsubmit()
                        }

                        else
                            seterror(true)
                        showAlert(response.data.msg)
                    }

                }).catch(err => {
                    console.error(`Error: ${err}`)
                })
        }
        else {
            e.target.reset()
            resetState()
        }

    }

    return (
        <div>
            <Form autocomplete="off" onSubmit={onsubmit} style={{ width: '100%' }} className="form-profile">
                <Stack gap={2} direction="vertical">
                    <div>
                        <Form.Group className="mb-3" controlId="formBasicName" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'between', gap: '4%' }}>
                            <div style={{ width: '48%' }}>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control defaultValue={props.user.firstname ?? ""} onInputCapture={optimisedHandleChange} onChange={optimisedHandleChange} controlId="firstname" name="firstname" type="text" placeholder="Name" />
                            </div>
                            <div style={{ width: '48%' }}>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control defaultValue={props.user.lastname ?? ""} onInputCapture={optimisedHandleChange} onChange={optimisedHandleChange} controlId="lastname" name="lastname" type="text" placeholder="Name" />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control defaultValue={props.user.email ?? ""} style={{ opacity: 0.5 }} disabled onInputCapture={optimisedHandleChange} onChange={optimisedHandleChange} name="email" type="email" placeholder="Enter email" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control defaultValue={props.user.username ?? ""} onInputCapture={optimisedHandleChange} onChange={optimisedHandleChange} name="username" placeholder="Enter username" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <InputGroup>
                                <Form.Control value={"••••••••••"} style={{ opacity: 0.5 }} disabled onInputCapture={optimisedHandleChange} onChange={optimisedHandleChange} name="username" placeholder="Enter username" />
                                <InputGroup.Text id="basic-addon2">
                                    <Button variant="link" className="change-password p-0" onClick={handleChangePasswordShow}>CHANGE PASSWORD</Button>
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <label className="form-label input-label">Country/Region</label>
                        <Form.Select className='select-box' name='country' onChange={optimisedHandleChange}
                            aria-label="Country/Region">
                            {countries.map((item) => {
                                return (
                                    <option selected={item.id == selected_country ? 'selected' : ''}
                                        key={item.id} value={item.id}>{item.name}</option>
                                )
                            })}
                        </Form.Select>
                        <Form.Group className="mb-3" controlId="affilation">
                            <Form.Label>Affiliation</Form.Label>
                            <Form.Control defaultValue={props.user.affiliation ?? ""} onInputCapture={optimisedHandleChange} onChange={optimisedHandleChange} name="affiliation" type="Affiliation" placeholder="Enter Affiliation" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="position">
                            <Form.Label>Position</Form.Label>
                            <Form.Control defaultValue={props.user.position ?? ""}
                                onInputCapture={optimisedHandleChange}
                                onChange={optimisedHandleChange}
                                name="position" type="Position" placeholder="Enter Position" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="position">
                            <Form.Label>LinkedIn</Form.Label>
                            <Form.Control defaultValue={props.user.linkedin_url ?? ""}
                                onInputCapture={optimisedHandleChange}
                                onChange={optimisedHandleChange}
                                name="linkedin" type="LinkedIn" placeholder="Enter LinkedIn" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="position">
                            <Form.Label>ResearchGate</Form.Label>
                            <Form.Control defaultValue={props.user.researchgate_url ?? ""}
                                onInputCapture={optimisedHandleChange}
                                onChange={optimisedHandleChange}
                                name="researchgate" type="ResearchGate" placeholder="Enter ResearchGate" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Bio</Form.Label>
                            <Form.Control defaultValue={props.user.bio ?? ""} onInputCapture={optimisedHandleChange} onChange={optimisedHandleChange} name="bio" as="textarea" rows={3} />
                        </Form.Group>
                    </div>

                    <Stack gap={2} direction="horizontal" className="d-flex justify-content-end">
                        <Button className="style-button btn-font-size" variant="primary" onClick={(e) => setcancelFlag(false)} type="submit" class="w-50">Save Changes</Button>
                    </Stack>
                </Stack>
                <Alert key={error ? 'danger' : 'success'} variant={error ? 'danger' : 'success'}
                    className='mt-2 alert-style'
                    hidden={alert_show}>
                    {alert_msg}
                </Alert>
            </Form>
            <Modal show={showChangePassword} onHide={handleChangePasswordClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change password</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <PasswordForm onHide={handleChangePasswordClose} />
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default UserForm