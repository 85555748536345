import React from 'react'
import '../css/Footer.css'
import { MdEmail, MdLocationOn } from 'react-icons/md'
import { FaLinkedinIn, FaInstagram, FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa'
const env = require('../../config.env')

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className='mx-0 px-0 row mt-5 px-3'>
                <div className='col-md-7'>
                    <div className='mx-0 px-0 row'>
                        <div className='ms-md-4 my-0 me-0 p-0 col-md-12'>
                            <img src={'/assets/pageLogo.png'} alt="logo" className='f-logo-size' />
                        </div>
                    </div>
                    <div className='mx-0 px-0 row'>
                        <div className='mx-lg-4 col-md-8'><span>Built for researchers, by researchers. Be a part of our academic community operated by artificial intelligence.
                        </span></div>
                    </div>

                    <div className='mx-0 my-4 px-0 row'>
                        <div className='mx-lg-4 col-md-8'>
                            <a className='social-link' target="_blank"
                                href='https://www.linkedin.com/company/37229472/admin/?anchor=org-view-followers'>
                                <FaLinkedinIn size={30} className='me-md-5 me-3' /></a>
                            <a className='social-link'
                                target="_blank"
                                href='https://www.instagram.com/researchtube.ai/'>
                                <FaInstagram size={30} className='me-md-5 me-3' /></a>
                            <a className='social-link'
                                target="_blank"
                                href='https://www.facebook.com/ResearchTubeAi'>
                                <FaFacebookF size={30} className='me-md-5 me-3' /></a>
                            <a className='social-link'
                                target="_blank"
                                href='https://twitter.com/ResearchTube_Ai'>
                                <FaTwitter size={30} className='me-md-5 me-3' /></a>
                            <a className='social-link'
                                target="_blank"
                                href='https://www.youtube.com/@ResearchTubeAi'>
                                <FaYoutube size={30} className='me-md-5 me-3' /></a>
                        </div>
                    </div>
                </div>
                <div className='col-md-5'>
                    <div className='mx-0 px-0 row'>
                        <div className='col-md-4'>
                            <div className='mx-0 my-4 text-md-left px-0 row'><div className='col-md-12 text-decoration'>Product </div></div>
                            <div className='mx-0 my-4 text-md-left px-0 row'><div className='col-md-12'> Home</div></div>
                            <div className='mx-0 my-4 text-md-left px-0 row'><div className='col-md-12'>Features </div></div>
                            <div className='mx-0 my-4 text-md-left px-0 row'><div className='col-md-12'>Resources </div></div>

                        </div>
                        <div className='col-md-8'>
                            <div className='mx-0 my-4 text-md-left px-0 row'><div className='col-md-12 text-decoration'> Contact</div></div>
                            <div className='mx-0 my-4 text-md-left px-0 row'><div className='col-md-12'> <MdEmail /> contact@researchtube.ai</div></div>
                            <div className='mx-0 my-4 text-md-left px-0 row'><div className='d-flex col-md-12'><MdLocationOn size={30} /><p>55 St. George Street, Suite 620 Toronto, ON M5S 1A4</p></div></div>
                        </div>

                    </div>
                </div>

            </div>
            <div className='mx-0 my-0 px-5 row'><span className='border border-bottom border-1'></span></div>
            <div className='mx-0 my-0 px-0 row  mt-2 pb-4'>
                <div className='col-12 px-5 col-md-8'>© 2024 ResearchTube.Ai</div>
                <div className='col-12 px-5 mx-0 text-lg-end text-sm-start col-md-4'>
                    <a className='ms-2 mt-2 text-decoration-none text-dark' target="_blank"
                        href={'/terms'}>Terms of Service & Privacy Policy</a>

                </div>
            </div>
        </div>

    )
}

export default Footer
